@use '../../styles/mixins' as mixins;
@use '../../styles/breakpoints' as bp;

.fund-card-grid {

	@include mixins.grid(400px);

	.fund-card {
		background-color: var(--wp--preset--color--bnpp-green-tint-10);
		position: relative;
		transition: 300ms;
		padding: var(--wp--preset--spacing--60);

		&:hover {
			background-color: var(--wp--preset--color--bnpp-green-tint-20);
		
		}

		.asset-classes {
			font-weight: 400;
			margin: 0;
			margin-bottom: var(--wp--preset--spacing--40);
		}

		h3 {
			color: var(--wp--preset--color--bnpp-black);
			font-size: var(--wp--preset--font-size--heading-s);
			text-wrap: pretty;
		}

		.block {
			background-color: var(--wp--preset--color--bnpp-green-tint-20);
			color: var(--wp--preset--color--bnpp-black);
			display: inline-block;
			margin: 0;
			padding: 5px 10px 1px;

			span {
				font-weight: 400;
			}
		}
		
	}

	&.dark {


		.fund-card {

			h3,
			.block {
				color: var(--wp--preset--color--white);
			}

		}

	}

}